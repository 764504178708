import React, { useEffect, useState, useRef } from 'react';
import { Content, Row, Badge, Col, Button, SimpleTable, Inputs } from 'adminlte-2-react';
import { getData, putData } from './Communication';
import $ from 'jquery';
import Modal from 'react-modal';
import JSONPretty from 'react-json-pretty';

function Research() {
  const isCalledRef = useRef(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 200);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState({});
  const [attributes, setAttributes] = useState([]);
  const [showInactiveItems, setShowInactiveItems] = useState(false);
  const [isBoosted, setIsBoosted] = useState(0);
  const [currentWineId, setCurrentWineId] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [jsonData, setJsonData] = useState({});
  const [text, setText] = useState('');

  const { Text, Checkbox, Radio, Select } = Inputs;

  const loadLastRec = async (id = null) => {
    let tenant = await localStorage.getItem('channelId');
    let channel = await localStorage.getItem('currentChannel');
    setData([]);
    if (id == null || id == '') {
      getData(process.env.REACT_APP_API_URL + '/designer/recommendations?channel=' + channel).then((res) => {
        setData(res.data);
      });
    } else {
      getData(process.env.REACT_APP_API_URL + '/designer/recommendations/' + id + '?channel=' + channel).then((res) => {
        setData([res.data]);
      });
    }
  };

  useEffect(() => {
    if (!isCalledRef.current) {
      loadLastRec();
    }
    $('.questionCol').css('overflow-y', 'auto');
  }, []);

  const openModal = (json) => {
    setModalIsOpen(true);
    setJsonData(json);
  };

  function formatTimestampToGermanDateTime(timestamp) {
    const date = new Date(timestamp);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  }

  return (
    <Content title="Recherche">
      <Row style={{ width: '100% !important', height: '100% !important' }}>
        <Col xs={10} style={{ marginTop: 30 }}>
          <Text placeholder="recId" onChange={(e) => setText(e.target.value)} label="Recommendation Id" style={{ zIndex: 1 }}></Text>
        </Col>
        <Col xs={1} style={{ marginTop: 30 }}>
          <input type="button" onClick={() => loadLastRec(text)} value="Suchen" style={{ marginLeft: 0, width: '90%', borderRadius: 7, padding: 8, backgroundColor: '#F2EEE8' }}></input>
        </Col>
      </Row>

      <Row style={{ width: '100% !important', height: '100% !important', marginLeft: 20 }}>
        <Col xs={12} className="questionCol">
          {data.map(
            (item, index) =>
              index <= 10 && (
                <Row className="researchItemLine" onClick={() => openModal([item.details, { tasteProfileType: item.tasteProfileType }, { tasteProfile: item.tasteProfile }, { moment: item.moment }])}>
                  <b>{item.id}</b>
                  <br />
                  <i>{formatTimestampToGermanDateTime(item.createdAt)}</i>
                </Row>
              )
          )}
        </Col>
      </Row>
      {modalIsOpen && <div className="custom-overlay"></div>}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        ariaHideApp={false}
        overlayClassName={'custom-overlay'}
        className="custom-content"
        style={{
          content: {
            top: '50%',
            left: '50%',
            width: '50%',
            height: '80%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 99999
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)' // Halbtransparenter schwarzer Hintergrund
          }
        }}
      >
        <h4>Recommendation Datensatz</h4>
        <JSONPretty data={jsonData} style={{ height: '800px', overflowX: 'auto', marginBottom: '20px' }} />
        <input type="button" onClick={() => setModalIsOpen(false)} value="Schließen" style={{ marginLeft: 0, width: '120px', borderRadius: 7, padding: 8, backgroundColor: '#F2EEE8' }}></input>
      </Modal>
    </Content>
  );
}

export default Research;
