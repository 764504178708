import React, { useEffect, useState, useRef } from 'react';
import { Content, Row, Badge, Col, Button, SimpleTable, Inputs } from 'adminlte-2-react';
import { getData, putData } from './Communication';
import $ from 'jquery';

function Tasteprofile() {
  const [data, setData] = React.useState([]);

  const { Text, Checkbox, Radio, Select } = Inputs;

  const getTastprofileSettings = async () => {
    getData(process.env.REACT_APP_API_URL + '/designer/settings')
      .then((res) => {
        console.log('GET: DESIGNER/KPIS', res);
        if (res) {
          setData(res.data);
        }
      })
      .catch((error) => console.log('ERROR', error));
  };

  React.useEffect(() => {
    getTastprofileSettings();
  }, []);

  const saveData = () => {
    data.map((item) => {
      putData(process.env.REACT_APP_API_URL + '/designer/settings/' + item.id, { value: item.value }).then((res) => {
        console.log('/designer/settings/' + item.id, { value: item.value });
      });
    });
  };

  return (
    <Content title="Geschmacksprofile">
      {data.map((item, index) => (
        <Row style={{ backgroundColor: index % 2 ? '#f2eee8' : '#FFF' }}>
          <Col>
            <Text
              name="wfGPvalue"
              placeholder=""
              label={item.id}
              labelSm="4"
              sm="1"
              labelPosition="left"
              value={item.value}
              onChange={(e) => {
                const { value } = e.target;
                setData((prevData) => prevData.map((item, idx) => (idx === index ? { ...item, value } : item)));
              }}
            />
          </Col>
        </Row>
      ))}
      <Row style={{ marginTop: 20 }}>
        <Col xs="5" style={{ textAlign: 'right' }}>
          <input type="button" className="btn btn-sm btn-default" value="Speichern" onClick={() => saveData()} />
        </Col>
      </Row>
    </Content>
  );
}

export default Tasteprofile;
