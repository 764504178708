import React, { useState, useRef } from 'react';
import { Content, Row, Col, SimpleTable, Tabs, TabContent } from 'adminlte-2-react';
import axios from 'axios';
import $ from 'jquery';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getData } from './Communication';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import { LineChart } from '@mui/x-charts';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'none'
    },
    title: {
      display: false,
      text: ''
    }
  }
};

export const optionsGP = {
  responsive: true,
  plugins: {
    legend: {
      position: 'none'
    },
    title: {
      display: false,
      text: ''
    }
  }
};

function Dashboard() {
  moment.locale('de');
  const triggerFunction = useSelector((state) => state.triggerFunction);
  const dispatch = useDispatch();
  const [activeClass, setActiveClass] = useState(false);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight - 120);
  const [currentQuestionId, setCurrentQuestionId] = useState(null);
  const [recommendations, setRecommendations] = useState([]);
  const [labels, setLabels] = useState([]);
  const [GpLabels, setGpLabels] = useState([]);
  const [abortIds, setAbortIds] = useState([]);
  const [wines, setWines] = useState([]);
  const [tenant, setTenant] = useState(null);
  const [gpData, setGpData] = useState();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const attrRecWineList = [
    { title: 'Artikelnummer', data: 'articleNo' },
    { title: 'Name', data: 'name' },
    { title: 'Häufigkeit', data: 'count' }
  ];

  React.useEffect(() => {
    loadTenant();
    if (startDate == null && endDate == null) {
      const yesterday = moment().subtract(1, 'days').format('DD.MM.YYYY');
      setEndDate(yesterday);
      let dateMinusOne = subtractOneMonth(yesterday);
      setStartDate(dateMinusOne);
    }
  }, []);

  const loadTenant = async () => {
    let tmpTenant = await localStorage.getItem('tenantId');
    setTenant(tmpTenant);
  };

  React.useEffect(() => {
    if (triggerFunction) {
      loadTenant();
      loadKpi();
      dispatch({ type: 'RESET_TRIGGER' });
    }
  }, [triggerFunction, dispatch]);

  const addWine = (object) => {
    setWines((prevNodes) => [...prevNodes, object]);
  };

  function subtractOneMonth(germanDate) {
    const parsedDate = moment(germanDate, 'DD.MM.YYYY', true);
    if (!parsedDate.isValid()) {
      throw new Error('Ungültiges Datum');
    }
    const newDate = parsedDate.subtract(1, 'months');
    return newDate.format('DD.MM.YYYY');
  }

  const convertMilliseconds = (milliseconds) => {
    var s = milliseconds / 1000;
    var minuten = Math.floor(s / 60);
    var sekunden = Math.round(s - minuten * 60);
    if (minuten <= 9) {
      minuten = '0' + minuten;
    }
    if (sekunden <= 9) {
      sekunden = '0' + sekunden;
    }

    return minuten + ':' + sekunden;
  };

  const toDate = (date) => {
    let convertedDate = date.toString().substring(6, 10) + '-' + date.toString().substring(3, 5) + '-' + date.toString().substring(0, 2);
    return convertedDate;
  };

  const toDateItem = (date, returnValue) => {
    if (returnValue == 'year') return date.toString().substring(6, 10);
    if (returnValue == 'month') {
      let month = parseInt(date.toString().substring(3, 5));
      month--;
      return month;
    }
    if (returnValue == 'day') return date.toString().substring(0, 2);
  };

  const loadKpi = () => {
    console.log('TENANT', tenant);
    if (startDate != null && endDate != null && tenant != null) {
      let from = new Date(toDateItem(startDate, 'year'), toDateItem(startDate, 'month'), toDateItem(startDate, 'day'), 0, 0).getTime();
      let to = new Date(toDateItem(endDate, 'year'), toDateItem(endDate, 'month'), toDateItem(endDate, 'day'), 23, 59, 59).getTime();
      let urlData = '?tenant=' + tenant + '&from=' + from + '&to=' + to;
      getData(process.env.REACT_APP_API_URL + '/designer/kpis' + urlData).then((res) => {
        if (res) {
          console.log('GET: DESIGNER/KPIS', res);
          setRecommendations(res.data.recommendations);
        }
        setIsLoading(false);
      });

      getData(process.env.REACT_APP_API_URL + '/designer/value-kpis' + urlData).then((gp) => {
        if (gp) {
          console.log('GET: DESIGNER/VALUE-KPIS', gp);
          gp.data = {
            Weißwein: {
              Mineralisch: {
                0: 0,
                1: 0,
                2: 0,
                3: 14,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0
              },
              Beerig: {
                0: 0,
                1: 0,
                2: 0,
                3: 4,
                4: 0,
                5: 0,
                6: 12,
                7: 0,
                8: 29,
                9: 0,
                10: 0
              }
            },
            Sonstiges: {
              Mineralisch: {
                0: 0,
                1: 0,
                2: 0,
                3: 1,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0
              }
            }
          };
          setGpData(gp.data);
        }
        setIsLoading(false);
      });
    }
  };

  function transformChartData(input) {
    // Initialisiere ein leeres Array für das Ergebnis
    const result = [];

    // Iteriere durch die Schlüssel des Eingabeobjekts
    for (const flavor in input) {
      if (input.hasOwnProperty(flavor)) {
        // Füge jedes Attribut in das Ergebnisarray hinzu
        result.push({
          label: flavor,
          data: Object.values(input[flavor]) // Extrahiere die Werte als Array
        });
      }
    }
    console.log('CHART DATA', result);
    return result;
  }

  const data = {
    labels,
    datasets: [
      {
        label: 'ABGEBROCHEN  BEI  FRAGE',
        data: abortIds,
        borderColor: '#cda58e',
        backgroundColor: '#cda58e',
        borderColor: '#cda58e'
      }
    ]
  };

  React.useEffect(() => {
    if (startDate != null && endDate != null && tenant != null) {
      setIsLoading(true);
      loadKpi();
      console.log('START', startDate, 'END', endDate, 'TENANT', tenant);
    }
  }, [startDate, endDate, tenant]);

  React.useEffect(() => {
    console.log('REC', recommendations);
    setWines([]);
    if (Object.keys(recommendations).length) {
      Object.keys(recommendations.wines).map((key, index) => {
        addWine({
          articleNo: recommendations.wines[key].id,
          name: recommendations.wines[key].name,
          count: recommendations.wines[key].count
        });
      });
      getData(process.env.REACT_APP_API_URL + '/designer/graph?tenant=' + tenant).then((res) => {
        console.log('GET: DESIGNER/GRAPH', res);
        const items = [];
        const data = [];
        if (res) {
          res.data.nodes.map((item) => {
            items.push(item.text);

            if (recommendations.stoppedAtQuestion[item.id] != undefined) {
              data.push(recommendations.stoppedAtQuestion[item.id]);
            } else {
              data.push(0);
            }

            data.push(0);
          });
          setLabels(items);
          setAbortIds(data);
        }
      });
    }
  }, [recommendations]);

  return (
    <Content title="Dashboard" subTitle="">
      <Row>
        <Col xs="2" style={{ paddingTop: '40px' }}>
          Von
          <br />
          <DatePicker
            clearIcon={null}
            style={{
              width: '100%'
            }}
            onChange={(e) => {
              let year = new Intl.DateTimeFormat('de', {
                year: 'numeric'
              }).format(e);
              let month = new Intl.DateTimeFormat('de', {
                month: '2-digit'
              }).format(e);
              let day = new Intl.DateTimeFormat('de', {
                day: '2-digit'
              }).format(e);
              console.log(day + '.' + month + '.' + year);
              setStartDate(day + '.' + month + '.' + year);
            }}
            value={startDate != null ? new Date(toDate(startDate)) : ''}
            format={'dd.MM.y'}
          />
        </Col>
        <Col xs="2" style={{ paddingTop: '40px' }}>
          Bis
          <br />
          <DatePicker
            clearIcon={null}
            onChange={(e) => {
              let year = new Intl.DateTimeFormat('de', {
                year: 'numeric'
              }).format(e);
              let month = new Intl.DateTimeFormat('de', {
                month: '2-digit'
              }).format(e);
              let day = new Intl.DateTimeFormat('de', {
                day: '2-digit'
              }).format(e);
              console.log(day + '.' + month + '.' + year);
              setEndDate(day + '.' + month + '.' + year);
            }}
            value={endDate != null ? new Date(toDate(endDate)) : ''}
            format={'dd.MM.y'}
          />
        </Col>
        <Col xs="4"></Col>

        <Col xs="4">
          {!isLoading && (
            <div className="kpiContainer">
              ConversionRate
              <br />
              <span style={{ fontWeight: 'bold' }}>{recommendations.conversionRatePercent != undefined && recommendations.conversionRatePercent.toString().replace('.', ',')}%</span>
            </div>
          )}
        </Col>
        <Col xs="12">
          <hr />
        </Col>
      </Row>
      {isLoading && (
        <Row>
          <Col xs="12">
            <h3>Bitte warten, die Daten werden geladen...</h3>
          </Col>
        </Row>
      )}
      {!isLoading && (
        <Row style={{ padding: 20 }}>
          <Tabs defaultActiveKey="tab_1">
            <TabContent title="Abbruchverhalten" eventKey="tab_1">
              <Col xs="12">
                <h4>ABBRUCHVERHALTEN</h4>
              </Col>
              <Col xs="12" sm="1">
                <div className="kpiContainer">
                  GESTARTET
                  <br />
                  <span>{recommendations.started}</span>
                </div>
                <div className="kpiContainer">
                  BEENDET
                  <br />
                  <span>{recommendations.ended}</span>
                </div>
                <div className="kpiContainer">
                  ABGEBROCHEN
                  <br />
                  <span>{recommendations.started - recommendations.ended}</span>
                </div>
              </Col>

              <Col xs="12" sm="11" style={{ paddingTop: 20 }}>
                <Line options={options} data={data} height={60} />
              </Col>
              <Row>
                <Col xs="12" style={{ paddingTop: '30px', paddingBottom: '10px' }}>
                  <hr></hr>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <h4>ZEITVERHALTEN</h4>
                </Col>
                <Col xs="4">
                  <div className="kpiContainer">
                    SCHNELLSTE
                    <br />
                    <span>{recommendations.durations ? convertMilliseconds(recommendations.durations.completedMin) : ''}</span>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="kpiContainer">
                    IM DURCHSCHNITT
                    <br />
                    <span>{recommendations.durations ? convertMilliseconds(recommendations.durations.completedAvg) : ''}</span>
                  </div>
                </Col>
                <Col xs="4">
                  <div className="kpiContainer">
                    LANGSAMSTE
                    <br />
                    <span>{recommendations.durations ? convertMilliseconds(recommendations.durations.completedMax) : ''}</span>
                  </div>
                </Col>
              </Row>
            </TabContent>
            <TabContent title="Geschmacksprofile" eventKey="tab_2" startDate={{ height: '750px' }}>
              <div style={{ width: '100%', height: '700px', overflowY: 'scroll' }}>
                <h4>Weißwein</h4>
                <LineChart
                  xAxis={[{ data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
                  yAxis={[{ data: [1, 1000] }]}
                  series={transformChartData(gpData['Weißwein'])}
                  width={window.innerWidth - 350}
                  height={600}
                  slotProps={{
                    legend: {
                      direction: 'column',
                      position: { vertical: 'top', horizontal: 'right' },
                      padding: 0
                    }
                  }}
                />
                <h4>Rotwein</h4>
                <LineChart
                  xAxis={[{ data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
                  yAxis={[{ data: [1, 1000] }]}
                  series={transformChartData(gpData['Rotwein'])}
                  width={window.innerWidth - 350}
                  height={600}
                  slotProps={{
                    legend: {
                      direction: 'column',
                      position: { vertical: 'top', horizontal: 'right' },
                      padding: 0
                    }
                  }}
                />
                <h4>Sonstiges</h4>
                <LineChart
                  xAxis={[{ data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] }]}
                  yAxis={[{ data: [1, 1000] }]}
                  series={transformChartData(gpData['Sonstige'])}
                  width={window.innerWidth - 350}
                  height={600}
                  slotProps={{
                    legend: {
                      direction: 'column',
                      position: { vertical: 'top', horizontal: 'right' },
                      padding: 0
                    }
                  }}
                />
              </div>
            </TabContent>
            <TabContent title="Empfohlene Weine" eventKey="tab_3">
              <Col xs="12">
                <h4>EMPFOHLENE WEINE</h4>
              </Col>
              <Col xs="12">
                {' '}
                <SimpleTable columns={attrRecWineList} data={wines} striped />
              </Col>
            </TabContent>
          </Tabs>
        </Row>
      )}
    </Content>
  );
}

export default Dashboard;
